import React from "react";
import { graphql } from "gatsby";

import "../queries/components_query";

import ContentBlocks from "../components/ContentBlocks/ContentBlocks";
import Layout from "../components/Layout/Layout";
import SEO from "../components/seo";

const IndexPage = ({ data }) => {
  const {
    meta_title,
    meta_description,
    body: blocks
  } = data.page.edges[0].node.data;
  const menu = data.prismicMenu.data;
  const footer = data.prismicFooter.data;

  const {
    cookie_message_button_text,
    cookie_message_text
  } = data.prismicGeneral.data;

  return (
    <Layout
      lang={"es"}
      menu={menu}
      footer={footer}
      cookie_message_button_text={cookie_message_button_text}
      cookie_message_text={cookie_message_text}
    >
      <SEO lang={"es"} title={meta_title} description={meta_description} />
      {blocks.map((block, index) => {
        if (!block || !block.slice_type) return;

        const { slice_type } = block;
        const componentName = slice_type
          ? slice_type.charAt(0).toUpperCase() + slice_type.slice(1)
          : null;
        return (
          <ContentBlocks
            tag={componentName}
            key={slice_type + index}
            {...block}
          />
        );
      })}
    </Layout>
  );
};

export const query = graphql`
  {
    page: allPrismicPage(filter: { uid: { eq: "home" } }) {
      edges {
        node {
          data {
            meta_title
            meta_description
            body {
              ...ComponentsQuery
            }
          }
        }
      }
    }

    prismicMenu {
      data {
        menu_icon {
          url
          alt
        }
        body {
          ... on PrismicMenuBodyLink {
            primary {
              link_text
              link_url
            }
          }
          ... on PrismicMenuBodyButton {
            primary {
              link_text
              link_url
              type
            }
          }
          __typename
        }
      }
    }

    prismicFooter {
      data {
        logo {
          url
          alt
        }
        social_instagram_link
        social_facebook_link
        social_linkedin_link
        links {
          link_text
          link_url
        }
        bottom_links {
          link_text
          link_url
        }
        show_language_switch
      }
    }

    prismicGeneral {
      data {
        cookie_message_button_text
        cookie_message_text {
          html
        }
      }
    }
  }
`;

export default IndexPage;
